body {
  margin: 0;
  font-family: "A1", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: A1, Arial, Helvetica, sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input.removeArrows::-webkit-outer-spin-button,
input.removeArrows::-webkit-inner-spin-button,
.removeArrows input::-webkit-outer-spin-button,
.removeArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.removeArrows[type="number"],
.removeArrows input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: A1;
  src: local('A1Sans-Regular'), url('./fonts/sans/woff2/A1Sans-Regular.woff2') format('woff2'), url('./fonts/sans/woff/A1Sans-Regular.woff') format('woff');
  font-display: swap;
}